<template>
  <v-sheet>
    <v-row class="px-3">
      <v-col cols="12" sm="10">
        <v-btn
          :block="$vuetify.breakpoint.xsOnly"
          depressed
          color="#04BF68"
          dark
          @click="showDialog()"
        >
          Add Bank Account
        </v-btn>
      </v-col>
      <v-col cols="12" sm="2">
        <!-- <v-select
          :items="['Foo', 'Bar', 'Fizz', 'Buzz']"
          label="Filter"
          dense
          outlined
        /> -->
      </v-col>
    </v-row>

    <v-row class="px-3">
      <v-col class="d-flex" cols="12" sm="6">
        <v-icon>mdi-dollar</v-icon>
        <v-text-field
          v-model="inputBankFee"
          type="number"
          label="Bank Fee"
          outlined
          dense
          hide-details
          class="mr-2"
        ></v-text-field>
        <v-btn
          :disabled="env.bank_fee === inputBankFee"
          fab
          small
          color="success"
          @click="saveBankFee"
          ><v-icon>mdi-content-save</v-icon></v-btn
        >
      </v-col>
      <v-col class="d-flex" cols="12" sm="6">
        <v-icon>mdi-paypal</v-icon>
        <v-text-field
          v-model="inputPaypalFee"
          label="Paypal Fee (%)"
          outlined
          dense
          hide-details
          class="mr-2"
        ></v-text-field>
        <v-btn
          :disabled="env.paypal_fee === inputPaypalFee"
          fab
          small
          color="success"
          @click="savePaypalFee"
          ><v-icon>mdi-content-save</v-icon></v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="data" :loading="loading">
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              color="primary"
              fab
              depressed
              x-small
              class="mx-2"
              @click="showDialog(item)"
            >
              <v-icon>mdi-pencil-box-outline</v-icon>
            </v-btn>
            <v-btn
              color="error"
              fab
              depressed
              x-small
              @click="deleteHandler(item)"
            >
              <v-icon>mdi-delete-forever</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogForm" persistent width="500">
      <v-card flat>
        <v-card-title class="grey">
          <div class="heading-4 font-weight-bold">
            {{ isEdit ? "Edit Bank Account" : "Add Bank Account" }}
          </div>
          <v-spacer />
          <v-icon class="font-weight-bold" @click="closeForm()">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.bank"
                  :rules="[(v) => !!v || 'Bank Name must be fill']"
                  name="bank-name"
                  label="Bank Name"
                  dense
                  outlined
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.account_name"
                  :rules="[(v) => !!v || 'Account Name must be fill']"
                  name="account-name"
                  label="Account Name"
                  dense
                  outlined
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.account_number"
                  :rules="[(v) => !!v || 'Account Number must be fill']"
                  name="account-number"
                  label="Account Number"
                  type="number"
                  dense
                  outlined
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.swift_code"
                  name="swift-code"
                  label="Swift Code"
                  dense
                  outlined
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.bank_address"
                  name="bank-address"
                  label="Bank Address"
                  dense
                  outlined
                />
              </v-col>
            </v-row>
            <v-divider class="mb-2" />
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn
                  :loading="loadingDialog"
                  width="150"
                  color="#04BF68"
                  dark
                  depressed
                  @click="saveBankAccount()"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ModalConfirm
      :dialog="dialogConfrim"
      :text="`Delete Bank Account ${deleteData.account_name} - ${deleteData.bank}?`"
      :loading="loadingDialog"
      title="Delete Bank Account"
      @action="(r) => deleteAction(r)"
    />
  </v-sheet>
</template>

<script>
import { getInfo, saveInfo } from "@/api/admin/settings";
import {
  getBankAcount,
  addBankAcount,
  updateBankAcount,
  deleteBankAcount,
} from "@/api/admin/settings";

import ModalConfirm from "@/components/ModalConfirm.vue";

export default {
  components: {
    ModalConfirm,
  },
  data() {
    return {
      loading: false,
      loadingDialog: false,
      dialogConfrim: false,
      dialogForm: false,
      data: [],
      deleteData: {
        account_name: null,
        id: null,
        bank: null,
        bank_address: null,
        swift_code: null,
      },
      form: {
        account_number: null,
        account_name: null,
        bank: null,
        bank_address: null,
        swift_code: null,
      },
      isEdit: false,
      env: {},
      inputBankFee: 0,
      inputPaypalFee: 0,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Bank",
          value: "bank",
        },
        {
          text: "Account Name",
          value: "account_name",
        },
        {
          text: "Account Number",
          value: "account_number",
        },
        {
          text: "Swift Code",
          value: "swift_code",
        },
        {
          text: "Bank Address",
          value: "bank_address",
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          width: 200,
        },
      ];
    },
  },
  mounted() {
    this.getBankAccount();
    getInfo().then((res) => {
      if (res.data.code) {
        this.env = res.data.data;
        if (this.env.bank_fee) {
          this.inputBankFee = Number(this.env.bank_fee);
        }
        if (this.env.paypal_fee) {
          this.inputPaypalFee = Number(this.env.paypal_fee);
        }
      }
    });
  },
  methods: {
    async saveBankFee() {
      this.env.bank_fee = this.inputBankFee;
      const { data } = await saveInfo(this.env);
      this.snackBar(data.code === 1, data.message);
    },
    async savePaypalFee() {
      this.env.paypal_fee = this.inputPaypalFee;
      const { data } = await saveInfo(this.env);
      this.snackBar(data.code === 1, data.message);
    },
    async deleteAction(param) {
      if (param) {
        this.loadingDialog = true;
        const { id } = this.deleteData;

        deleteBankAcount({ id })
          .then((res) => {
            const { data } = res;
            this.snackBar(data.code === 1, data.message);
          })
          .catch(() => this.snackBar(false, "something went wrong"))
          .finally(() => {
            this.loadingDialog = false;
            this.dialogConfrim = false;
            this.deleteData = { account_name: null, id: null, bank: null };
            this.getBankAccount();
          });
      } else this.dialogConfrim = false;
    },
    closeForm(param) {
      if (param === "save") this.getBankAccount();
      this.dialogForm = false;
      this.isEdit = false;
      this.$nextTick(() => {
        this.form.account_name = null;
        this.form.bank = null;
        this.form.account_number = null;
        this.form.swift_code = null;
        this.form.bank_address = null;
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      });
    },
    showDialog(item) {
      this.dialogForm = true;
      if (item) {
        this.form.bank = item.bank;
        this.form.account_name = item.account_name;
        this.form.account_number = item.account_number;
        this.form.swift_code = item.swift_code;
        this.form.bank_address = item.bank_address;
        this.form.id = item.id;
        this.isEdit = true;
      }
    },
    getBankAccount() {
      this.loading = true;
      getBankAcount(this.query)
        .then((res) => {
          const { data } = res;
          if (data.code) {
            this.data = data.data;
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    deleteHandler(item) {
      const { id, account_name, bank, swift_code, bank_address } = item;
      this.deleteData.id = id;
      this.deleteData.account_name = account_name;
      this.deleteData.bank = bank;
      this.deleteData.swift_code = swift_code;
      this.deleteData.bank_address = bank_address;
      this.dialogConfrim = true;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("SET_SNACKBAR", {
        value: true,
        text: msg,
        color: isSuccess ? "success" : "error",
      });
    },
    async saveBankAccount() {
      this.loadingDialog = true;
      const {
        account_name,
        account_number,
        bank,
        id,
        swift_code,
        bank_address,
      } = this.form;

      if (this.isEdit) {
        updateBankAcount({
          id,
          bank,
          account_name,
          account_number,
          swift_code,
          bank_address,
        })
          .then((res) => {
            const { code, message } = res.data;
            if (code) this.snackBar(code === 1, message);
          })
          .catch((e) => {
            console.error("updateBank()\n", e);
          })
          .finally(() => {
            this.loadingDialog = false;
            this.closeForm("save");
          });
      } else {
        addBankAcount({
          bank,
          account_name,
          account_number,
          swift_code,
          bank_address,
        })
          .then((res) => {
            const { code, message } = res.data;
            if (code) this.snackBar(code === 1, message);
          })
          .catch((e) => {
            console.error("updateBank()\n", e);
          })
          .finally(() => {
            this.loadingDialog = false;
            this.closeForm("save");
          });
      }
    },
  },
};
</script>
