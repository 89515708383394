import request from "@/utils/request";

export function addBankAcount(data) {
  return request({
    method: "post",
    url: "/bank-account/add",
    data,
  });
}

export function getBankAcount() {
  return request({
    method: "post",
    url: "/bank-account/get",
  });
}

export function updateBankAcount(data) {
  return request({
    method: "post",
    url: "/bank-account/update",
    data,
  });
}

export function deleteBankAcount(data) {
  return request({
    method: "post",
    url: "/bank-account/delete",
    data,
  });
}

export function getInfo() {
  return request({
    method: "post",
    url: "/environment/get",
  });
}

export function saveInfo(data) {
  return request({
    method: "post",
    url: "/environment/set",
    data,
  });
}

export function getSlideImages(data) {
  return request({
    method: "post",
    url: "/slidebar/get",
    data,
  });
}

export function addSlideImage(data) {
  return request({
    method: "post",
    url: "/slidebar/add",
    data,
  });
}

export function updateSlideImage(data) {
  return request({
    method: "post",
    url: "/slidebar/update",
    data,
  });
}

export function deleteSlideImage(data) {
  return request({
    method: "post",
    url: "/slidebar/delete",
    data,
  });
}
